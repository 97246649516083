import type { GetServerSideProps, NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router';
import { useEffect } from 'react';
import Homepage from '../components/templates/home/home';

export const runOldRedirects = (router: NextRouter) => {
  const canRun = typeof window !== 'undefined';
  if (!canRun) {
    return false;
  }
  if (window.location.hash) {
    const matchesTreasurer = window.location.hash.match(
      /#\/org\/([^/]*)\/manage.*/
    );
    if (matchesTreasurer && matchesTreasurer.length >= 2) {
      window.location.replace(
        `https://adventistgiving.org/#/org/${matchesTreasurer[1]}/manage`
      );
      return true;
    }
    const matchesDonate = window.location.hash.match(/#\/org\/([^/]*).*/);
    if (matchesDonate && matchesDonate.length >= 2) {
      router.replace(`/donate/${matchesDonate[1]}`);
      return true;
    }
    return false;
  }
};

const Home: NextPage = () => {
  const router = useRouter();

  useEffect(() => {
    runOldRedirects(router);
  }, [router]);
  return <Homepage />;
};

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  let orgId: string = '';
  let data = Object.keys(query).filter((k) => k.toLowerCase() === 'orgid');
  if (data) orgId = query[data[0]] as string;
  if (orgId) {
    return {
      redirect: {
        destination: `/donate/${orgId}`,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default Home;
