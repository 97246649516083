import { useRouter } from 'next/router';
import { useState } from 'react';

import FocusArea from '../../organisms/focus-area/focus-area';
import VerseQuote from '../../molecules/verse-quote/verse-quote';
import SliderHome2 from '../../organisms/slider-home-2/slider-home-2';
import SignUpSuggest from '../../organisms/sign-up-suggest/sign-up-suggest';
import { SimpleTemplateContainer } from '..';
import SliderHome1 from '../../organisms/slider-home-1/slider-home-1';
import { useGetOrganization } from '@hooks/use-organization';
import { ChurchEnrollmentModal } from '@components/organisms/church-enrollment-modal/church-enrollment-modal';

const Homepage = () => {
  const router = useRouter();
  const { query } = router;
  const [churchUnrolledId, setChurchUnrolledId] = useState(
    query && query.notEnrolledId ? `${query.notEnrolledId}` : ''
  );
  const { data: organization } = useGetOrganization({
    id: query && query.notEnrolledId ? `${query.notEnrolledId}` : '',
  });

  return (
    <SimpleTemplateContainer withMargin={false}>
      <ChurchEnrollmentModal
        isOpen={churchUnrolledId ? true : false}
        isNotEnrolled={churchUnrolledId ? true : false}
        close={() => {
          setChurchUnrolledId('');
        }}
        organization={organization}
      />
      <SliderHome1 />
      <VerseQuote />
      <SliderHome2 />
      <FocusArea />
      <SignUpSuggest />
    </SimpleTemplateContainer>
  );
};

export default Homepage;
